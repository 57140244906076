@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Livvic:wght@600&display=swap');

body {
  background-color: #33374D !important;
  height: 100%;
  padding-bottom: 200px;
}

.logo-title {
  font-family: 'Alfa Slab One', cursive;
  color: #B685FF;
  font-size: 20px;
}

.logo-subtitle {
  font-family: 'Livvic', sans-serif;
  color: #FFFFFF;
  font-size: 11px !important;
}

h1  {
  font-family: 'Alfa Slab One', cursive;
  color: #B685FF;
  font-size: 20px !important;
  cursor: default;
  user-select: none;
}

h2  {
  font-family: 'Livvic', sans-serif;
  color: #FFF;
  cursor: default;
  user-select: none;
}

h4  {
  font-family: 'Livvic', sans-serif;
  color: #FFF;
  font-size: 15px !important;
  cursor: default;
  user-select: none;
}

.logo {
  width: 200px;
}

.category-title {
  font-family: 'Livvic', sans-serif;
  color: #FFFFFF;
  font-size: 26px !important;
  background-color: #1F2A3D;
  cursor: default;
  user-select: none;
}

.mobile-footer {
  display: none !important;
}

.sidebar-desktop {
  display: relative !important;
}

@media only screen and (max-width: 768px) {
  h1  {
   font-size: 36px !important;
  }

  h2  {
    font-size: 22px !important;
   }

   .logo {
    width: 150px;
  }

  .mobile-footer {
    display: flex !important;
  }

  .sidebar-desktop {
    display: none !important;
  }

  .footer {
    margin-bottom: 120px;
  }
}